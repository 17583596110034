exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-aboutus-aboutus-js": () => import("./../../../src/templates/aboutus/aboutus.js" /* webpackChunkName: "component---src-templates-aboutus-aboutus-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-design-pusher-design-pusher-js": () => import("./../../../src/templates/designPusher/designPusher.js" /* webpackChunkName: "component---src-templates-design-pusher-design-pusher-js" */),
  "component---src-templates-homepage-categories-js": () => import("./../../../src/templates/homepage/categories.js" /* webpackChunkName: "component---src-templates-homepage-categories-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/new.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/product/product.js" /* webpackChunkName: "component---src-templates-product-product-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-services-services-js": () => import("./../../../src/templates/services/services.js" /* webpackChunkName: "component---src-templates-services-services-js" */),
  "component---src-templates-sold-js": () => import("./../../../src/templates/sold.js" /* webpackChunkName: "component---src-templates-sold-js" */),
  "component---src-templates-stories-stories-js": () => import("./../../../src/templates/stories/stories.js" /* webpackChunkName: "component---src-templates-stories-stories-js" */),
  "component---src-templates-stories-story-js": () => import("./../../../src/templates/stories/story.js" /* webpackChunkName: "component---src-templates-stories-story-js" */),
  "component---src-templates-subcategory-js": () => import("./../../../src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */)
}

